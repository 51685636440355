import React from "react"
import { graphql } from "gatsby"

import { getImage, ImageDataLike } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { MDXRenderer } from "gatsby-plugin-mdx"
import BackgroundImage from 'gatsby-background-image'


import { Container, Row, Col, Button } from "react-bootstrap"
import Url from "url-parse"

import Layout from "../components/layout"
import Icon from "../components/icon"

import * as styles from "../styles/artistpage.module.css"

import { RouteComponentProps } from "@reach/router"

interface ArtistQueryProps extends RouteComponentProps {
    data: {
        strapiArtist: {
            id: number
            name: string
            youtubeVideoUrl: string
            youtubeUrl: string
            website: string
            spotifyUrl: string
            spotifyAlbumUrl: string
            slug: string
            instagramUrl: string
            facebookUrl: string
            description: {
                data : {
                    childMdx: {
                        body: string
                    }
                }
            }
            engDescription: {
                data : {
                    childMdx: {
                        body: string
                    }
                }
            }
            image: {
                localFile: ImageDataLike
            }
            rider: {
                url: string
            }
        }
    }
    pageContext: any
}

export default function ArtistPage({ data, location, pageContext } : ArtistQueryProps ) {
    const { strapiArtist } = data
    const image = getImage(strapiArtist.image.localFile)!
    const bgImage = convertToBgImage(image);

    const websiteUrl = new Url(strapiArtist.website);
    const spotifyAlbumUrl = new Url(strapiArtist.spotifyAlbumUrl);
    const youtubeVideoUrl = new Url(strapiArtist.youtubeVideoUrl, true);
    const defaultLang = "hu";
    if (pageContext.lang === "en") {
        strapiArtist.description = strapiArtist.engDescription;
    }
    
    return (
        <Layout lang={pageContext.lang} pageMeta={{title: strapiArtist.name}} background={"#fff"} location={location} defaultLang={defaultLang} navbarBackground={"var(--black)"}>
            <div className={styles.thumbnail_wrapper}>
                <BackgroundImage Tag="div"
                {...bgImage}
                className={styles.thumbnail_photo}
                >
                    <div className={styles.thumbnail_name}>
                        <h1>{strapiArtist.name}</h1>
                    </div>
                </BackgroundImage>

                <BackgroundImage Tag="div"
                {...bgImage}
                className={styles.thumbnail_bg}
                >
                </BackgroundImage>
            </div>

            <Container className={styles.artist_content_wrapper} fluid>
                <Row className={styles.artist_socialmedia_wrapper}>
                    <Col className="my-auto" xs={12} md={8}>
                        <div className={styles.artist_socialmedia_icon_wrapper}>
                            {strapiArtist.facebookUrl  && <a href={strapiArtist.facebookUrl}  className={styles.artist_socialmedia_link}> <Icon className={styles.artist_socialmedia_icon} icon="facebookIcon"/>  </a> }
                            {strapiArtist.instagramUrl && <a href={strapiArtist.instagramUrl} className={styles.artist_socialmedia_link}> <Icon className={styles.artist_socialmedia_icon} icon="instagramIcon"/> </a> }
                            {strapiArtist.youtubeUrl   && <a href={strapiArtist.youtubeUrl}   className={styles.artist_socialmedia_link}> <Icon className={styles.artist_socialmedia_icon} icon="youtubeIcon"/>   </a> }
                            {strapiArtist.spotifyUrl   && <a href={strapiArtist.spotifyUrl}   className={styles.artist_socialmedia_link}> <Icon className={styles.artist_socialmedia_icon} icon="spotifyIcon"/>   </a> }
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className={styles.artist_socialmedia_website_wrapper}>
                            {strapiArtist.website ?
                                <a className={styles.artist_website} href={strapiArtist.website} >{websiteUrl.hostname.replace('www.', '')} </a> 
                            : <span className={styles.artist_website}>&nbsp;</span>}
                        </div>
                    </Col>
                </Row>

                <Row className={styles.artist_maincontent_wrapper}>
                    <Col>
                        <div className={styles.artist_maintext}>
                            <MDXRenderer>{strapiArtist.description.data?.childMdx.body ?? ""}</MDXRenderer>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.artist_media_wrapper}>
                    {strapiArtist.youtubeVideoUrl && strapiArtist.spotifyAlbumUrl ?
                    <>
                        <Col lg={8} className={`embed-responsive text-center ${styles.artist_youtube_wrapper}`}>
                            <iframe className={`${styles.artist_youtube} embed-responsive-item`} src={`https://www.youtube.com/embed/${youtubeVideoUrl.query['v']}`}
                                    width="560" height="380"
                                    title={`${strapiArtist.name} Youtube`}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    frameBorder="0"
                                    allowFullScreen>
                            </iframe>
                        </Col>

                        <Col lg={4} className={`${styles.artist_media_right_column} embed-responsive text-center`}>
                                <iframe className={`${styles.artist_spotify} embed-responsive-item`} src={`https://open.spotify.com/embed/album/${spotifyAlbumUrl.pathname.split('/')[2]}`}
                                width="300" height="380" 
                                title={`${strapiArtist.name} Spotify`}
                                allow="encrypted-media"/>

                                {strapiArtist.rider && 
                                    <a href={strapiArtist.rider.url} download target="_blank">
                                        <Button variant="dark" size="lg" className={styles.artist_rider} >
                                            {pageContext.lang === "hu" ? "Rider letöltése" : "Download Rider"}
                                        </Button>
                                    </a>
                                }
                        </Col>
                    </>
                    : strapiArtist.youtubeVideoUrl ? 
                    <>
                        <Col lg={8} className={`embed-responsive text-center ${styles.artist_youtube_wrapper}`}>
                            <iframe className={`${styles.artist_youtube} embed-responsive-item`} src={`https://www.youtube.com/embed/${youtubeVideoUrl.query['v']}`}
                                    width="560" height="380"
                                    title={`${strapiArtist.name} Youtube`}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                            </iframe>

                            {strapiArtist.rider && 
                                <a href={strapiArtist.rider.url} download target="_blank">
                                    <Button variant="dark" size="lg" className={styles.artist_rider} >
                                        {pageContext.lang === "hu" ? "Rider letöltése" : "Download Rider"}
                                    </Button>
                                </a>
                            }
                        </Col>
                    </>
                    : strapiArtist.spotifyAlbumUrl ? 
                    <>
                         <Col lg={4} className={`${styles.artist_media_right_column} embed-responsive text-center`}>
                                <iframe className={`${styles.artist_spotify} embed-responsive-item`} src={`https://open.spotify.com/embed/album/${spotifyAlbumUrl.pathname.split('/')[2]}`}
                                width="300" height="380" 
                                title={`${strapiArtist.name} Spotify`}
                                allow="encrypted-media"/>

                                {strapiArtist.rider && 
                                    <a href={strapiArtist.rider.url} download target="_blank"> 
                                        <Button variant="dark" size="lg" className={styles.artist_rider} >
                                            {pageContext.lang === "hu" ? "Rider letöltése" : "Download Rider"}
                                        </Button>
                                    </a>
                                }
                        </Col>
                    </>
                    :
                    <>
                        
                        {strapiArtist.rider && 
                        <Col lg={4}>
                        
                            <a href={strapiArtist.rider.url} download target="_blank">
                                <Button variant="dark" size="lg" className={styles.artist_rider} >
                                    {pageContext.lang === "hu" ? "Rider letöltése" : "Download Rider"}
                                </Button>
                            </a>
                        </Col>
                        }
                        
                    </>
                    }
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
query ArtistQuery($slug: String!) {
    strapiArtist(slug: {eq: $slug}) {
        id
        name
        youtubeVideoUrl
        youtubeUrl
        website
        spotifyUrl
        spotifyAlbumUrl
        slug
        instagramUrl
        facebookUrl
        description {
            data {
                childMdx {
                    body
                }
            }
        }
        engDescription {
            data {
                childMdx {
                    body
                }
            }
        }
        image {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        transformOptions: {cropFocus: ATTENTION}
                        )
                }
            }
        }
    }
}
`

// rider {
//     url
// }
