// extracted by mini-css-extract-plugin
export var artist_content_wrapper = "artistpage-module--artist_content_wrapper--gUkfj";
export var artist_maincontent_wrapper = "artistpage-module--artist_maincontent_wrapper--fhbkZ";
export var artist_maintext = "artistpage-module--artist_maintext--1pa9V";
export var artist_media_right_column = "artistpage-module--artist_media_right_column--HuxAi";
export var artist_media_wrapper = "artistpage-module--artist_media_wrapper--OlM7h";
export var artist_rider = "artistpage-module--artist_rider--VCttL";
export var artist_socialmedia_icon = "artistpage-module--artist_socialmedia_icon--x++GO";
export var artist_socialmedia_icon_wrapper = "artistpage-module--artist_socialmedia_icon_wrapper--GJnoD";
export var artist_socialmedia_link = "artistpage-module--artist_socialmedia_link--7DJVy";
export var artist_socialmedia_website_wrapper = "artistpage-module--artist_socialmedia_website_wrapper--6x4Ge";
export var artist_socialmedia_wrapper = "artistpage-module--artist_socialmedia_wrapper--nhA0H";
export var artist_spotify = "artistpage-module--artist_spotify--ESAyp";
export var artist_website = "artistpage-module--artist_website--oBpQQ";
export var artist_youtube = "artistpage-module--artist_youtube--anvmk";
export var artist_youtube_wrapper = "artistpage-module--artist_youtube_wrapper--ZVYwU";
export var thumbnail_bg = "artistpage-module--thumbnail_bg--e6PhF";
export var thumbnail_name = "artistpage-module--thumbnail_name--Kbq2y";
export var thumbnail_photo = "artistpage-module--thumbnail_photo---Q5U1";
export var thumbnail_wrapper = "artistpage-module--thumbnail_wrapper--drZ1l";